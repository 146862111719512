import React from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {applyMiddleware, createStore} from "redux"
import {Provider, useDispatch, useSelector} from "react-redux"
import AppReducers from "./App/reducers";
import {Grid} from '@mui/material';
import {Alert} from "./App/Alert";
import {Login} from "./Auth/Login";
import logger from "redux-logger"
import {render} from "react-dom"
import thunk from "redux-thunk"
import './index.css';
import {NoPage} from "./App/NoPage";
import {Loading} from "./App/Loading";
import {AccountActions} from "./Account/actions/account";
import {Header} from "./Account/Header";
import {Index} from "./Modules/Index";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
        ].join(','),
    },
    palette: {
        secondary: {
            main: "#808080"
        },
        success: {
            main: "#33BE63"
        },
        primary: {
            main: "#2595FF"
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#FFFFFF',
                    color: 'rgba(0, 0, 0, 0.87)',
                    borderRadius: '2px',
                    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.3);',
                    fontSize: '12px',
                    lineHeight: '15px',
                    padding: '15px 17px 15px 12px'
                },
                arrow: {
                    color: '#FFFFFF'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderRadius: '4px'
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#2595FF',
                        color: 'white',
                        border: 'none',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontStyle: 'normal'
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    padding: "2px",
                    fontSize: "14px",
                    paddingLeft: "5px",
                    borderRadius: "2px",
                    border: "1px solid #DBDEDF",
                    '&.Mui-focused': {
                        border: "1px solid #7BBFFF",
                        borderRadius: "2px"
                    },
                    '&.MuiInputLabel-root': {
                        marginLeft: "5px",
                        marginTop: "2px"
                    },
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&.MuiInput-focused': {
                        border: "1px solid #7BBFFF",
                        borderRadius: "2px"
                    },
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginLeft: "5px",
                    marginTop: "2px",
                }
            }
        }
    }
});

let middleware = [thunk]
if (process.env.REACT_APP_MODE !== 'production') {
    middleware = [...middleware, logger]
}

const store = createStore(
    AppReducers,
    applyMiddleware(...middleware)
)

function RequireAuth({children}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation()
    const {account} = useSelector(state => state.account);

    if (!account.username) {
        if (localStorage.getItem("token") !== null) {
            let auth = dispatch(AccountActions.account(navigate)).then(_ => {
                return true
            })
            if (auth.result === undefined) {
                return children;
            }
        }
        return <Navigate to="/login" state={{from: location}} replace />;
    } else {
        return children;
    }
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Grid container direction='column' justify='center' alignItems='stretch' style={{height: '100%'}}>
                <Loading/>
                <Alert/>
                <BrowserRouter>
                    <Routes>
                        <Route element={
                            <div style={{height: "100%"}}>
                                <Outlet/>
                            </div>
                        }>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/data/patients" element={<RequireAuth><Header/><Index/></RequireAuth>}/>
                            <Route path="/" element={<Navigate replace to="/data/patients"/>}/>
                            <Route path="*" element={<NoPage/>}> </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Grid>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);
