import React from "react"
import {useDispatch, useSelector} from "react-redux"
import Snackbar from '@mui/material/Snackbar';
import {AlertActions} from "./actions/alert"
import MuiAlert from '@mui/material/Alert';
import {makeStyles} from "@mui/styles";
import {AlertTitle} from "@mui/lab";
import Typography from "@mui/material/Typography";
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    alert: {
        background: "white",
        borderRadius: "2px",
        filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16))",
        boxShadow: "none"
    },
    alert_message: {
        color: "black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: "20px"
    },
    alert_description: {
        color: "#495055",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px"
    }
}))

const CustomAlert = React.forwardRef(function Alert(props, ref) {
    const {onClose} = props

    return <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled" {...props}
        action={
            <IconButton
                onClick={onClose}
                size="small"
            >
                <CloseIcon style={{color: "#495055"}}/>
            </IconButton>
        }
    />;
});

export const Alert = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert)

    const close = () => {
        dispatch(AlertActions.clear())
    }

    if (alert.message) {
        return (
            <Snackbar
                open={true}
                autoHideDuration={3500}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                onClose={close}
            >
                <CustomAlert
                    onClose={close}
                    className={classes.alert}
                    style={{color: alert.type === 'success' ? "#33BE63" : "#DF3826"}}
                    severity={alert.type} sx={{width: '100%'}}
                >
                    <AlertTitle className={classes.alert_message}>{alert.message}</AlertTitle>
                    <Typography className={classes.alert_description}>Повторите запрос еще раз,<br/>либо проверьте
                        введеные данные.</Typography>
                </CustomAlert>
            </Snackbar>
        )
    } else {
        return null
    }
}
