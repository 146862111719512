import {combineReducers} from "redux"
import authentication from "../../Auth/reducers/authentication";
import account from "../../Account/reducers/account";
import patient from "../../Modules/Patients/reducers/patient";
import loading from "./loading";
import alert from "./alert";

const CombineReducers = combineReducers({
    authentication,
    loading,
    account,
    alert,
    patient
})

const AppReducers = (state, action) => {
    return CombineReducers(state, action)
}

export default AppReducers
