import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {PatientService} from '../services/patient'

export const PatientActions = {
    patients,
    enableSync,
    disableSync
}

function patients(filter = {}, state = true) {
    return dispatch => new Promise((resolve, reject) => {
        if (state) dispatch({type: "LOADING", payload: true})

        PatientService.patients(httpBuildQuery(filter))
            .then(
                response => {
                    if (state) {
                        dispatch({type: "PATIENT_VALUES", payload: response})
                        dispatch({type: "LOADING", payload: false})
                    }
                    resolve(response)
                },
                error => {
                    if (state) dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function enableSync(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PatientService.enableSync(httpBuildQuery(values))
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function disableSync(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PatientService.disableSync(id)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

