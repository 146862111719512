import React from "react"
import {Breadcrumbs, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from "@mui/material/Button";
import Home from '../components/Svg/Home'
import {makeStyles} from '@mui/styles';
import {Link, useLocation} from 'react-router-dom';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    home_icon: {
        width: "16px",
        height: "16px",
        display: "block"
    },
    prev: {
        fontStyle: "normal",
        color: "black",
        textDecoration: "none",
        textTransform: "none",
        fontWeight: 400,
        fontSize: "16px",
        fontHeight: "16px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
    current: {
        color: "#6E7880"
    },
}))

const partitions = [
    {
        name: "Данные",
        path: "data",
        modules: [
            {
                name: "Пациенты",
                path: "patients"
            }
        ]
    }
]

export const CustomBreadcrumb = (props) => {
    const classes = useStyles()
    const {partitionId, onClickPath} = props
    const location = useLocation();

    const parseModules = (modules, paths) => {
        let breadcrumbs = []

        modules.forEach(module => {
            if (module.path === paths[2] || module.path === paths[3]) {
                breadcrumbs.push(<Typography className={clsx(classes.prev, classes.current)}>{module.name}</Typography>)
            }
            if (module.modules) {
                breadcrumbs.push(parseModules(module.modules, paths))
            }
        })
        return breadcrumbs
    }

    const getBreadcrumb = () => {
        let breadcrumbs = []
        const paths = location.pathname.split('/')

        partitions.forEach((partition, index) => {
            if (partition.path === paths[1]) {
                breadcrumbs.push(<Button
                    className={classes.prev}
                    underline="hover"
                    color="inherit"
                    onClick={onClickPath}
                    key={index}
                >
                    {partitions[partitionId].name}
                </Button>)
                breadcrumbs.push(parseModules(partition.modules, paths))
            }
        })
        return breadcrumbs
    }

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small"/>}
        >
            <Link to="/">
                <Home className={classes.home_icon}/>
            </Link>
            {getBreadcrumb()}
        </Breadcrumbs>
    )
}
