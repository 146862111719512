import React from "react";
import {fieldToTextField} from "formik-material-ui";
import {withStyles} from '@mui/styles';
import {TextField} from '@mui/material';


const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `2px`,
            },
        },
    },
})(TextField);

export const TextFieldWithError = (props) => {
    const {
        classes,
        inputProps,
        ...otherProps
    } = fieldToTextField(props)

    return (
        <CustomTextField
            classes={classes}
            {...otherProps}
            InputProps={inputProps}
            onChange={inputProps && inputProps.hasOwnProperty('onChange') && inputProps.onChange !== null ? inputProps.onChange : function (event) {
                props.form.setFieldValue(props.field.name, otherProps.type === 'number' ? Number(event.target.value) : event.target.value)
            }}
        />
    )
}
