import Skeleton from "@mui/material/Skeleton";
import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const getSkeleton = (rowCount = 9, cellCount = 13) => {
    let i,
        cells = [],
        rows = []
    for (i = 0; i < cellCount; i++) {
        cells[i] = <TableCell key={i}><Skeleton variant="text" width="100%" height={40}/></TableCell>
    }
    for (i = 0; i < rowCount; i++) {
        rows[i] = <TableRow key={i}>{cells}</TableRow>
    }
    return rows
}

export {getSkeleton};
