import React, {useState} from "react"
import {Patients} from "./Patients/Patietns";
import PatientsIcon from "../App/components/Svg/Patients";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Grid, Tab, Tabs} from '@mui/material';
import {allyProps} from "../App/helpers/tabPanel";
import {useDispatch} from "react-redux";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    height: "100%"
  },
  container: {
    height: "100%",
    width: "100%",
    margin: 0,
  },
  tab_list: {
    width: "18%",
    "color": "#495055",
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#2595FF',
      borderRadius: "0px 4px 4px 0px",
      width: "4px",
      left: 0
    },
    '& .MuiTabs-flexContainer': {
      height: "100%",
    }
  },
  icon: {
    height: "26px",
    width: "36px",
    display: "block"
  },
  tab: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "none",
    minHeight: "40px",
    height: "40px",
    textAlign: "center",
    justifyContent: "flex-start",
    '&.Mui-selected': {
      color: '#2595FF',
      backgroundColor: "#F1F9FF",
    }
  },
  sub_tab: {
    paddingLeft: "60px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "none",
    minHeight: "40px",
    height: "auto",
    textAlign: "left",
    justifyContent: "flex-start",
    '&.Mui-selected': {
      color: '#2595FF',
      backgroundColor: "#F1F9FF",
    }
  },
  filter: {
    backgroundColor: "#F5F5F5",
    padding: "10px 10px 10px 10px"
  },
  filter_name: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "25px",
    color: "black"
  }
}))

const modules = [
  {index: '1', name: 'patients', path: '/data/patients', component: <Patients />}
]

export const Index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [module, setModule] = useState(modules.find(module => module.path === location.pathname))

  const handleClick = (event) => {
    dispatch({type: "FILTER_CLEAR", payload: null})
    dispatch({type: "STUDY", payload: null})
    switch (event.target.id.slice(-2).replaceAll('-','')) {
      case '1':
        navigate('/data/patients')
        break
      default:
        navigate('/404')
        break
    }
  };

  const getColorIcon = (name) => {
    return (module?.name === name) ? '#2595FF' : 'black'
  }

  return (
    <Grid>
      <Box
        sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%"}}
      >
        <Tabs
          orientation="vertical"
          value={module?.index}
          className={classes.tab_list}
          aria-label="Выбор меню"
          sx={{borderRight: 1, borderColor: 'divider'}}
        >
          <Tab
            onClick={handleClick}
            className={classes.tab}
            label="Пациенты"
            icon={<PatientsIcon className={classes.icon} color={getColorIcon('patients')}/>}
            iconPosition="start"
            value={'1'}
            {...allyProps(1)}
          />
        </Tabs>
        {module?.component}
      </Box>
    </Grid>
  );
}
