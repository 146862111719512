import {HttpService} from "../../../App/services/http"

export const PatientService = {
    patients,
    enableSync,
    disableSync
}

function patients(filter = '') {
    const options = {
        method: "GET"
    }
    return HttpService.http({path: `/patient-index/patients?${filter}`}, options, true)
        .then(response => {
            return response
        })
}

function enableSync(values) {
    const options = {
        method: "PATCH"
    }

    return HttpService.http({path: `/patient-index/patient?${values}`}, options, true)
        .then(response => {
            return response
        })
}

function disableSync(id) {
    const options = {
        method: "PATCH"
    }

    return HttpService.http({path: `/patient-index/patient/${id}`}, options, true)
        .then(response => {
            return response
        })
}