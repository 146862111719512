import React, { useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import getActiveStyle from "../App/helpers/activeStyleOrPath";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Button from "@mui/material/Button";
import Avatar from '@mui/material/Avatar';
import Badge from "@mui/material/Badge";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    app_bar: {
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
        zIndex: 1000,
        background: "white",
    },
    parent: {
        fontWeight: 600,
        textDecoration: "none",
        color: "black",
        fontSize: "19px",
        lineHeight: "1.6",
        fontFamily: "Open Sans",
        background: "white",
        "&:hover":{
            background: "white",
        }
    },
    menu_item: {
        width: "100%",
        height: "4px",
        transition: "0.2s"
    },
    active_menu_item: {
        background: "#2595FF",
        borderRadius: "0px 0px 4px 4px"
    },
    menu_item_button: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        color: "#495055",
        textDecoration: "none",
        padding: "6px 8px",
        marginTop: "16px",
        marginBottom: "16px",
        display: "block"
    },
    active_menu_item_button: {
        "color": "#2595FF",
    },
    upload_manager: {
        "margin-right": "22px"
    },
    upload_manager_icon: {
        width: "32px",
        height: "32px",
    },
}))

export const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles()
    const location = useLocation();
    const {account} = useSelector(state => state.account);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const openUploadManager = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        localStorage.clear()
        navigate('/login')
    };

    const pages = [
        {
            "name": 'Данные',
            "path": "/data/patients"
        }
    ];

    const goHome = () => {
        dispatch({type: "FILTER_CLEAR", payload: null})
        navigate('/')
    }
    return (
        <AppBar
            position="static"
            color="transparent"
            className={classes.app_bar}
        >
            <Container maxWidth="false">
                <Toolbar disableGutters>
                    <Button
                        className={classes.parent}
                        onClick={goHome}
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                    >
                        Индекс пациентов
                    </Button>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Grid key={page.name}>
                                <Grid
                                    className={clsx(classes.menu_item, getActiveStyle(page.path, location.pathname, classes.active_menu_item))}
                                />
                                <Link
                                    to={page.path}
                                    className={clsx(classes.menu_item_button, getActiveStyle(page.path, location.pathname, classes.active_menu_item_button))}
                                    sx={{my: 2, color: 'black', display: 'block'}}
                                >
                                    <Typography>
                                        {page.name}
                                    </Typography>
                                </Link>
                            </Grid>
                        ))}
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Настройки">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt="">
                                    <AccountCircleIcon/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={logout}>
                                <Typography textAlign="center">Выход</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
