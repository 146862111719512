import React, {useEffect, useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useDispatch, useSelector} from "react-redux";
import Pagination from '@mui/material/Pagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import {getSkeleton} from "./helpers/table";
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {PatientActions} from "./actions/patient";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {Row} from "./components/Data/Row";

const useStyles = makeStyles(() => ({
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    paper: {
        boxShadow: 'none'
    },
    container: {
        height: "calc(100vh - 315px)"
    },
    arrow_cell: {
        width: 20,
    },
    cell: {
        fontWeight: 600,
        padding: '16px 5px'
    }
}))

const columns = [
    {name: '', width: 50},
    {name: 'Идентификатор', width: 200},
    {name: 'Фамилия', width: 200},
    {name: 'Имя', width: 200},
    {name: 'Отчество', width: 200},
    {name: 'Дата рождения', width: 200},
    {name: 'Документ', width: 200},
    {name: 'Количество связанных записей', width: 200},
    {name: '', width: 50}
];

export const Data = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {patients, total_pages, filter} = useSelector(state => state.patient);
    const [page, setPage] = useState(1)
    const {loading, setLoading} = props

    useEffect(() => {
        if (!loading) {
            console.log(filter)
            dispatch(PatientActions.patients(filter)).then(() => {
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    useEffect(() => {
        setLoading(false)
    }, [filter]);

    const toPage = (event, value) => {
        dispatch({type: "PATIENT_FILTER", payload: {...filter, ...{offset: value === 1 ? 0 : (filter.limit * value) - filter.limit}}})
        setPage(value);
        setLoading(false)
    };

    return (
        <Grid>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        className={classes.cell}
                                        key={index}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? patients.map((patient, index) => (
                                <Row patient={patient} key={index} />
                            )) : getSkeleton(9, 9)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid className={classes.footer}>
                    <Pagination
                        onChange={toPage}
                        page={page}
                        count={total_pages}
                        variant="outlined"
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Paper>
        </Grid>
    )
}
