import React from 'react';
import {useDispatch} from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import {TextFieldWithError} from '../../App/components/Input/TextField';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Field, Form, Formik} from 'formik';
import * as Yup from "yup";
import {PatientActions} from "./actions/patient";

const useStyles = makeStyles(() => ({
  form: {
    background: "white",
    padding: "35px"
  },
  send_button: {
    border: "1px solid #33BE63",
    boxSizing: "border-box",
    background: "#33BE63",
    borderRadius: "2px",
    boxShadow: "none",
    marginTop: "35px",
    color: "white",
    "&:hover": {
      background: "#33BE63",
    }
  },
  cancel_button: {
    border: "1px solid #d32f2f",
    boxSizing: "border-box",
    borderRadius: "2px",
    boxShadow: "none",
    marginTop: "35px",
    marginLeft: "15px",
    color: "white",
  }
}))

export const SyncForm = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {patient, setModuleValue} = props

  const textFieldProps = {
    disableUnderline: true
  }

  const handleClose = () => {
    setModuleValue("1")
    dispatch({type: 'PATIENT_VALUE', payload: null})
  }

  return (
    <Grid>
      <Formik
        initialValues={{
          parentPatientEntryId: patient.id,
          childPatientEntryId: ''
        }}
        validationSchema={Yup.object().shape({
          childPatientEntryId: Yup.string()
            .required("Введите ID пациента"),
          parentPatientEntryId: Yup.string()
            .required("Выберите пациента")
        })}
        onSubmit={(values, {setSubmitting}) => {
          return dispatch(PatientActions.enableSync(values)).then(
            () => {
              handleClose()
              setSubmitting(false);
            },
            errors => {
              if (errors) {}
              setSubmitting(false);
            }
          )
        }}
      >
        {({
            errors,
            values,
            handleSubmit,
            setFieldValue
          }) => (
          <Form className={classes.form}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <Field
                  type="text"
                  size="small"
                  align="center"
                  variant="standard"
                  label="ID пациента"
                  name={`childPatientEntryId`}
                  onChange={setFieldValue}
                  inputProps={textFieldProps}
                  style={{marginLeft: "10px"}}
                  className={classes.text_field}
                  component={TextFieldWithError}
                  placeholder="Введите ID пациента"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <LoadingButton
                variant="contained"
                onClick={handleSubmit}
                className={classes.send_button}
              >
                Добавить
              </LoadingButton>
              <LoadingButton
                color="error"
                variant="contained"
                onClick={handleClose}
                className={classes.cancel_button}
              >
                Отменить
              </LoadingButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
