import React, {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SyncDisabledOutlinedIcon from "@mui/icons-material/SyncDisabledOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import TableContainer from "@mui/material/TableContainer";
import {useDispatch, useSelector} from "react-redux";
import {PatientActions} from "../../actions/patient";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import {makeStyles} from '@mui/styles';
import format from "date-fns/format";
import {ru} from "date-fns/locale";

const useStyles = makeStyles(() => ({
    child: {
        padding: "8px"
    }
}))

export const Row = (props) => {
    const classes = useStyles()
    const {patient} = props
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {filter} = useSelector(state => state.patient);

    const getArrow = (id, children = false) => {
        return <TableCell>
            <Stack direction="row" alignItems="center">
                <IconButton
                    aria-label="Раскрыть"
                    size="small"
                    onClick={() => {
                        if (!open && !children) {
                            dispatch(PatientActions.patients({parentPatientId: id}, false)).then(response => {
                                dispatch({type: 'PATIENT_VALUE_CHILDREN', payload: {id: id, data: response.data}})
                            })
                        }
                        setOpen(!open)
                    }}
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </Stack>
        </TableCell>
    }

    const getParams = (patient) => {
        return <React.Fragment>
            <TableCell>{patient.id}</TableCell>
            <TableCell>{patient.lastName}</TableCell>
            <TableCell>{patient.firstName}</TableCell>
            <TableCell>{patient.middleName}</TableCell>
            <TableCell>{patient.birthDate ? format(new Date(patient.birthDate), 'dd.MM.yyyy', {locale: ru}) : null}</TableCell>
            <TableCell>{patient.identityDocument ? `${patient.identityDocument.name} ${patient.identityDocument.series} ${patient.identityDocument.number}` : null}</TableCell>
        </React.Fragment>
    }

    return patient ? (
        <React.Fragment>
            <TableRow hover>
                {!!patient.childPatientsCount ? getArrow(patient.id, (patient.hasOwnProperty('children') && !!patient.children.length)) :
                    <TableCell/>}
                {getParams(patient)}
                <TableCell>{patient.childPatientsCount}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="edit"
                        component="span"
                        onClick={() => {
                            dispatch({type: 'PATIENT_VALUE', payload: patient})
                        }}
                    >
                        <SyncOutlinedIcon color="primary"/>
                    </IconButton>
                </TableCell>
            </TableRow>
            {(open && patient.hasOwnProperty('children')) &&
                <TableRow hover>
                    <TableCell colSpan={9}>
                        <TableContainer>
                            <Table aria-label='sticky table'>
                                <TableBody>
                                    {patient.children.map((child, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell className={classes.child}/>
                                                <TableCell className={classes.child}>{child.id}</TableCell>
                                                <TableCell className={classes.child}>{child.lastName}</TableCell>
                                                <TableCell className={classes.child}>{child.firstName}</TableCell>
                                                <TableCell className={classes.child}>{child.middleName}</TableCell>
                                                <TableCell
                                                    className={classes.child}>{child.birthDate ? format(new Date(child.birthDate), 'dd.MM.yyyy', {locale: ru}) : null}</TableCell>
                                                <TableCell
                                                    className={classes.child}>{child.identityDocument ? `${child.identityDocument.name} ${child.identityDocument.series} ${child.identityDocument.number}` : null}</TableCell>
                                                <TableCell className={classes.child}/>
                                                <TableCell className={classes.child}>
                                                    <IconButton
                                                        aria-label="edit"
                                                        component="span"
                                                        onClick={() => {
                                                            dispatch(PatientActions.disableSync(child.id)).then(() => {
                                                                dispatch({
                                                                    type: 'PATIENT_VALUE_CHILD_DELETE',
                                                                    payload: {id: patient.id, childId: child.id}
                                                                })
                                                                dispatch(PatientActions.patients(filter)).then(() => {
                                                                })
                                                            })
                                                        }}
                                                    >
                                                        <SyncDisabledOutlinedIcon color="error"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment>
    ) : null
}
