import {HttpService} from "../../App/services/http"

export const AuthService = {
    login
}

function login(values) {
    const options = {
        method: "POST",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: "/patient-index/auth/login"}, options)
        .then(response => {
            return response
        })

}

