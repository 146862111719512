import React, {useEffect, useState} from "react"
import {CustomBreadcrumb} from "../../App/components/CustomBreadcrumb";
import {useDispatch, useSelector} from "react-redux";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {makeStyles} from '@mui/styles';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import {Data} from "./Data";
import {SyncForm} from "./SyncForm";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        background: "#F9FAFA",
        padding: "24px 0px 0px 24px;"
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "24px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "60px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const Patients = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {patient} = useSelector(state => state.patient);
    const [loading, setLoading] = useState(false);
    const [moduleValue, setModuleValue] = useState('1');

    useEffect(() => {
        if (patient) {
            setModuleValue("2")
        }
    },[patient])

    const handleChangeModule = (event, newValue) => {
        setModuleValue(newValue);
        if (newValue === "1") {
            dispatch({type: 'PATIENT_VALUE', payload: null})
            setLoading(false)
            cancelButton()
        }
    };

    const cancelButton = () => {
        dispatch({type: "STUDY", payload: null})
        setModuleValue('1')
    }

    return (
        <Grid className={classes.container}>
            <CustomBreadcrumb partitionId={0} onClickPath={cancelButton}/>
            <Grid style={{width: "98%"}}>
                <TabContext value={moduleValue}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChangeModule}
                            aria-label="Пункт меню"
                        >
                            <Tab
                                className={classes.tab}
                                label="Данные" value={'1'}
                            />
                            {patient &&
                                <Tab
                                    className={classes.tab}
                                    label="Добавить связь" value={'2'}
                                />
                            }
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="1">
                        <Data
                          loading={loading}
                          setLoading={setLoading}
                        />
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="2">
                        {patient &&
                            <SyncForm patient={patient} setModuleValue={setModuleValue}/>
                        }
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
