const filter = {
    limit: 10,
    offset: 0,
}

export default function patient(state = {
    patients: [],
    patient: null,
    total_pages: 0,
    filter: filter
}, action) {
    switch (action.type) {
        case "PATIENT_VALUES":
            return {
                ...state,
                ...{
                    patients: action.payload.data,
                    total_pages: action.payload.totalPages,
                }
            }
        case "PATIENT_VALUE":
            return {
                ...state,
                ...{
                    patient: action.payload
                }
            }
        case "PATIENT_VALUE_CHILDREN":
            return {
                ...state,
                ...{
                    patients: state.patients.map(patient => {
                        if (action.payload.id === patient.id) {
                            patient.children = action.payload.data
                        }
                        return patient
                    })
                }
            }
        case "PATIENT_VALUE_CHILD_DELETE":
            return {
                ...state,
                ...{
                    patients: state.patients.map(patient => {
                        if ((action.payload.id === patient.id) && patient.hasOwnProperty('children')) {
                            patient.children = patient.children.filter(child => (child.id !== action.payload.childId))
                        }

                        return patient
                    })
                }
            }
        case "PATIENT_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload,
                }
            }
        case "PATIENT_FILTER_CLEAR":
            return {
                ...state,
                ...{
                    filter: filter
                }
            }
        default:
            return state
    }
}
