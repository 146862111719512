export default function loading(state = {
    loading: false
}, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}
